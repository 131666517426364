/**
 * Gets the user's browser locale.
 *
 * This can be useful for setting the default locale for the user when they
 * first visit the app.
 *
 * @returns {string} The user's browser locale.
 */
export function getBrowserLocale() {
  const language = navigator.language ||
    navigator.browserLanguage ||
    navigator.systemLanguage ||
    navigator.userLanguage;
  
  return language.split('-')[0];
}