import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'select']

  connect() { }

  insert() {
    const selectedValue = this.selectTarget.options[this.selectTarget.selectedIndex].value;
    this.inputTarget.value += selectedValue;
    this.selectTarget.selectedIndex = 0;
  }
}
