import { Controller } from 'stimulus'
import StateDomElement from 'helpers/stateDomElement'

export default class extends Controller {
  static targets = ['trashCta']

  connect() {
    this.stateDom = new StateDomElement()
    // If I only have one section, I remove the possibility of deleting it.
    if (this.trashCtaTargets.length === 1) {
      this.stateDom.hideElTailwind(this.trashCtaTarget)
    }
  }

  /**
   * Date : 06/12/2024
   * Clicking on 'Add a section' displays the section deletion button for
   * each section.
   */
  activeTrashPossibleSection() {
    this.trashCtaTargets.forEach(trashCta => {
      this.stateDom.showElTailwind(trashCta)
    })
  }

  /**
   * Date : 06/12/2024
   * If I only have one section, I remove the possibility of deleting it.
   */
  checkPossibleTrashOrNot() {
    const trashCtas = this.trashCtaTargets
    if (trashCtas.length === 2) this.stateDom.hideElTailwind(this.trashCtaTarget)
  }
}
