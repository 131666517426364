// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus'
import Dropdown from 'stimulus-dropdown'
import Lightbox from 'stimulus-lightbox'
import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import Clipboard from '@stimulus-components/clipboard'
import FormPartial from './form_partial_controller'
import StimulusTomSelect from 'stimulus-tom-select'
import RevealController from '@stimulus-components/reveal'
import TextareaAutogrow from 'stimulus-textarea-autogrow'

export const application = Application.start()
application.register('checkbox-select-all', CheckboxSelectAll)
application.register('dropdown', Dropdown)
application.register('lightbox', Lightbox)
application.register('clipboard', Clipboard)
application.register('form-partial', FormPartial)
application.register('tom-select', StimulusTomSelect)
application.register('reveal', RevealController)
application.register('textarea-autogrow', TextareaAutogrow)
window.Stimulus = application

const controllers = import.meta.glob('./**/*_controller.js', { eager: true })

for (let path in controllers) {
  let module = controllers[path]
  let name = path.replace(/^\.\//, '').replace(/\//g, '--').replaceAll('_', '-').replace('-controller', '').replace('.js', '')
  application.register(name, module.default)
}
