import { Controller } from 'stimulus'
import StateDomElement from 'helpers/stateDomElement'

export default class extends Controller {
  static targets = [
    'customModalContent',
    'subModal',
    'subModalInSidePanel',
    'switchSelect',
    'modalContent',
    'modalContentInSidePanel',
    'triggerBtn',
    'triggerForm'
  ]
  actionId = null
  modalContent = null
  modalTitle = null
  switchSelectValue = null
  switchSelectValueToConfirm = null

  connect() {
    if (this.hasSwitchSelectTarget) {
      this.switchSelectValue = this.switchSelectTarget.value
    }
    this.stateDom = new StateDomElement()
  }

  openModalWithPreventDefault(event) {
    event.preventDefault()
    this.openModal(event)
    event.stopPropagation()
  }

  openModal(e) {
    this.actionId = null
    this.validModal = null
    if (e.currentTarget.dataset.modalActionId) {
      this.actionId = e.currentTarget.dataset.modalActionId
    }

    if (e.currentTarget.dataset.modalTitle) {
      this.modalTitle = e.currentTarget.dataset.modalTitle
      this.customModalContentTarget.querySelector('h6').innerText = this.modalTitle
    }

    if (e.currentTarget.dataset.modalContent) {
      this.modalContent = e.currentTarget.dataset.modalContent
      this.customModalContentTarget.querySelector('p').innerText = this.modalContent
    }

    if (this.hasModalContentInSidePanelTarget) {
      this.manageOpenModal(this.subModalInSidePanelTarget, this.modalContentInSidePanelTarget)
    } else {
      this.manageOpenModal(this.subModalTarget, this.modalContentTarget)
    }
  }

  closeModal() {
    if (this.hasModalContentInSidePanelTarget) {
      this.manageCloseModal(this.subModalInSidePanelTarget, this.modalContentInSidePanelTarget)
    } else {
      this.manageCloseModal(this.subModalTarget, this.modalContentTarget)
    }
    if (document.querySelector('.form-modal__content')) document.querySelector('.form-modal__content').style.overflowY = 'auto'
    if (document.querySelector('.popup__container')) document.querySelector('.popup__container').style.height = 'auto'
  }

  manageOpenModal(subModal, modalContent) {
    subModal.scrollTop = 0
    modalContent.scrollTop = 0
    subModal.classList.add('overflow--hidden')
    if (modalContent.classList.contains('d-none')) {
      modalContent.classList.remove('d-none')
    } else {
      modalContent.classList.remove('hidden')
    }
  }

  manageCloseModal(subModal, modalContent) {
    subModal.closest('body').classList.remove('overflow-hidden')
    subModal.classList.remove('overflow--hidden')
    if (modalContent.classList.contains('d-none')) {
      modalContent.classList.add('d-none')
    } else {
      modalContent.classList.add('hidden')
    }
  }

  confirmAction(event) {
    if (this.actionId) {
      const currentLink = this.triggerBtnTargets.find(elem => elem.dataset.modalActionId.toString() === this.actionId.toString())
      currentLink.click()
    } else if (this.hasTriggerFormTarget) {
      this.triggerFormTarget.submit()
    } else {
      this.triggerBtnTarget.click()
    }
    if (event.currentTarget.dataset.close === 'false') return
    this.closeModal()
  }

  getValueOnSwitch(e) {
    this.switchSelectValueToConfirm = e.currentTarget.value
  }

  confirmValueOnSwitch(e) {
    if (this.hasSwitchSelectTarget) {
      if (e.currentTarget.dataset.confirmSwitch === 'true') {
        this.switchSelectTarget.value = this.switchSelectValueToConfirm
      } else {
        this.switchSelectTarget.value = this.switchSelectValue
      }
    }
  }
}
