import Sortable from '@stimulus-components/sortable'

export default class extends Sortable {
  static targets = ['position']

  connect() {
    super.connect()
  }

  onUpdate(event) {
    super.onUpdate(event)

    this.sortable.toArray().forEach((id, index) => {
      let el = this.positionTargets.find(el => el.dataset.id == id)

      if (el) el.value = index
    })
  }

  get defaultOptions() {
    return {
      dataIdAttr: 'data-id',
      preventOnFilter: false,
      filter: 'input'
    }
  }
}
