import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = { options: Object };

  connect() {
    this.selectInstance = new TomSelect(this.element, {
      ...this.optionsValue,
      onDropdownOpen: () => this.showInput(),
      onDropdownClose: (value) => this.addValueSelected(value)
    });
  }

  addValueSelected(value) {
    if (value) {
      const parentNode = this.element.parentNode;
      parentNode.querySelector('.item').style.zIndex = 999;
      parentNode.querySelector('input').style.zIndex = 900;
    }
  }

  showInput() {
    const parentNode = this.element.parentNode;
    if(parentNode.querySelectorAll('.item').length) {
      parentNode.querySelector('.item').style.zIndex = 900;
      parentNode.querySelector('input').style.zIndex = 999;
    }
  }
  disconnect() {
    if (this.selectInstance) {
      this.selectInstance.destroy();
    }
  }
}
