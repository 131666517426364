import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['lineVat', 'lineTotal', 'invoiceTotal', 'invoiceTotalVat']

  connect() {
    this.computeInvoiceTotal()
  }

  computeInvoiceTotal() {
    setTimeout(() => {
      let sum = 0
      this.lineVatTargets.forEach((vat, index) => {
        const lineTotal = this.lineTotalTargets[index].value
        if (lineTotal) {
          const vatValue = parseFloat(lineTotal.replace(',', '.')) * parseFloat(vat.value.replace(',', '.'))
          sum += vatValue
        }
      })
      this.invoiceTotalVatTarget.textContent = sum.toFixed(2).replace('.', ',')

      const total = this.lineTotalTargets.reduce((acc, input, index) => {
        if (input.value) {
          const value = parseFloat(input.value.replace(',', '.'))
          return acc + (value + value * parseFloat(this.lineVatTargets[index].value.replace(',', '.')))
        } else {
          return acc
        }
      }, 0)

      this.invoiceTotalTarget.textContent = total.toFixed(2).replace('.', ',')
    }, 100)
  }
}
