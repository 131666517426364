import dropdown_controller from '../dropdown_controller'
import StateDomElement from 'helpers/stateDomElement'

export default class extends dropdown_controller {
  static targets = ['input']
  stateDom = new StateDomElement()
  connect() {
    if (this.hasMenuTarget) super.connect()
  }

  /**
   * Date 12/11/2024
   * @param {HTMLElement} el -  Line encapsulating the link clicked by the user
   * @param {Boolean} addAction - True if add description action else false
   */
  hideLinkInDropdown(el, addAction) {
    const parentList = el.parentNode
    const addDescLink = parentList.querySelector('#addDesc')
    const deleteDescLink = parentList.querySelector('#deleteDesc')
    this.stateDom.hideElTailwind(el)
    addAction ? this.stateDom.showElTailwind(deleteDescLink) : this.stateDom.showElTailwind(addDescLink)
  }

  /**
   * Date 12/11/2024
   * When user click on 'Ajouter la description' or 'Supprimer une description' - Input is show or hide
   * @param {Object} event - Current Event
   */
  showHideDescInput(event) {
    const currentTarget = event.currentTarget
    const parentLink = currentTarget.parentNode
    const isAddDesc = this.stateDom.checkHasAttrWithThisValue(parentLink, 'id', 'addDesc')
    const id = this.stateDom.getAt(currentTarget, 'data-action-id')
    this.inputTargets.forEach(input => {
      const idCurrentInput = this.stateDom.getAt(input, 'data-action-id')
      if (idCurrentInput === id) {
        isAddDesc ? this.stateDom.showElTailwind(input) : this.stateDom.hideElTailwind(input)
      }
    })
    this.hideLinkInDropdown(parentLink, isAddDesc)
  }
}
