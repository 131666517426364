import { Controller } from 'stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static values = {
    options: Object,
    linkContent: String
  }

  static targets = ['select', 'id', 'type', 'name', 'price', 'vat', 'totalPrice', 'quantity']

  connect() {
    const optionsValue = this.optionsValue

    if (!this.selectTarget.tomselect) {
      this.select = new TomSelect(this.selectTarget, {
        create: true,
        valueField: 'value',
        labelField: 'text',
        searchField: 'text',
        options: optionsValue,
        optgroupField: 'class',
        optgroups: [
          { value: 'Commission', label: 'Commissions' },
          { value: 'Prestation', label: 'Prestations' },
          { value: 'Autre', label: 'Autres' }
        ]
      })
      this.select.addOption({
        class: 'Autre',
        value: this.selectTarget.dataset.selected,
        text: this.selectTarget.dataset.selected
      })

      this.select.setValue(this.selectTarget.dataset.selected)

      this.select.on('item_remove', value => {
        this.idTarget.value = ''
        this.typeTarget.value = ''
        this.nameTarget.value = ''
      })

      this.select.on('change', value => {
        this.select.control_input.style.zIndex = -1
        const selectedOption = Object.values(optionsValue)
          .flat()
          .find(option => option.value == value)

        if (selectedOption) {
          this.idTarget.value = selectedOption.id
          this.typeTarget.value = selectedOption.class

          if (selectedOption.price) this.priceTarget.value = selectedOption.price.toFixed(2).replace('.', ',')

          if (selectedOption.vat) this.vatTarget.value = selectedOption.vat

          if (selectedOption.price && this.quantityTarget.value)
            this.totalPriceTarget.value = (selectedOption.price * this.quantityTarget.value).toFixed(2).replace('.', ',')
        } else {
          this.nameTarget.value = value
        }
      })

      this.select.control_input.addEventListener('input', e => {
        e.currentTarget.style.zIndex = 900
      })
    }
  }
}
