import { Controller } from "stimulus"
import LocalStore from "../../helpers/localStore"
import { DispatchDataStayCalendar } from "../../calendars/stays/dispatchDataStayCalendar"
import { ManageDatesFlatpickr } from "../../calendars/stays/manageDatesFlatpickr"
import charactersCounter from "../../utils/counterCharacters"

export default class extends Controller {
  static targets = ["inputWithCounter"]
  dispatchData = new DispatchDataStayCalendar()
  store = new LocalStore()

  connect() {
    this.displayCreateStayForm()
  }

  countCharacters() {
    const inputCounter = this.inputWithCounterTarget
    const inputCounterLimit = this.inputWithCounterTarget.getAttribute("data-limit-counter")
    charactersCounter(inputCounter, inputCounterLimit)
  }

  displayCreateStayForm() {
    // this.dispatchData.dispatchDataStay()
    const stayData = this.store.get("staySelected")
    if (stayData !== null) this.setDefaultDatesInCreateStayForm(stayData)
  }

  handleHousingChange(e) {
    const id = e.target.value
    const unAvailableDates = JSON.parse(localStorage.getItem("unavailableData"))
    let unAvailableDatesFormat = []
    if (unAvailableDates[id] !== undefined) {
      unAvailableDatesFormat = unAvailableDates[id].map(unAvailable => unAvailable.startDate)
    }
    const datePicker = document.getElementById("stayDateRange")._flatpickr
    datePicker.set(
      "disable",
      unAvailableDatesFormat.map(date => new Date(date))
    )
    datePicker.redraw()
  }

  /**
   * Date : 24/10/2024
   * Add differents attributes of flatpickr, when user selected dates in the calendar
   * @param {Object} data - Range date selected by user
   */
  setDefaultDatesInCreateStayForm(data) {
    const flatPickrClass = new ManageDatesFlatpickr()
    const inputStayRange = document.getElementById("stayDateRange")
    flatPickrClass.manageFlatpickrAttributes(inputStayRange, data.startDate, data.endDate)
  }
}
