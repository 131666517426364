import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['ownerContainer']

  change(event) {
    if (event.target.selectedOptions[0].value) {
      let ownerId = event.target.selectedOptions[0].value
      let target = this.ownerContainerTarget.id

      fetch(`/invoicing/owners/${ownerId}?target=${target}`, {
        method: 'GET',
        headers: { Accept: "text/vnd.turbo-stream.html" }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text();
        })
        .then(html => {
          this.ownerContainerTarget.innerHTML = html
        })
        .catch(error => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  }
}
