import { Controller } from 'stimulus'
import StateDomElement from 'helpers/stateDomElement'

export default class extends Controller {
  static targets = ['radio', 'fieldset']

  connect() {
    this.stateDom = new StateDomElement()
  }

  toggleFieldset(event) {
    const value = event.currentTarget.value

    this.fieldsetTargets.forEach(fieldset => {
      fieldset.dataset.fieldsetTypes.includes(value) ? this.stateDom.removeClass(fieldset, ['d-none']) : this.stateDom.addClass(fieldset, ['d-none'])
    })
  }
}
