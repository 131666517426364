import { Controller } from "@hotwired/stimulus";
import { getBrowserLocale } from "helpers/getLocale";

export default class extends Controller {
  static targets = ['phone', 'phoneLink', 'prefixLang']
  connect() {
    this.locale = getBrowserLocale()
    const encodedPhone = this.phoneTarget.dataset.phoneBase64;
    const prefix = this.locale === 'fr' ? 'au' : 'to';

    if (encodedPhone) {
      // Décodage Base64 et affichage
      const decodedPhone = atob(encodedPhone);
      if(this.hasPrefixLangTarget) this.prefixLangTarget.textContent = prefix
      this.phoneTarget.textContent = decodedPhone
      this.phoneLinkTarget.href = `tel:${decodedPhone}`
    }
  }
}

