import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'select', 'unitPrice', 'totalPrice', 'quantity']
  static outlets = ['invoices--total-price']

  connect() {
    this.form = this.element
    this.inputTargets.forEach(input => input.addEventListener('input', this.#save))
  }

  clear() {
    this.selectTarget.selectedIndex = 0
    this.#save()
  }

  computeLineTotal() {
    if (this.unitPriceTarget.value && this.quantityTarget.value) {
      const unitPrice = parseFloat(this.unitPriceTarget.value.replace(',', '.'))
      const quantity = parseFloat(this.quantityTarget.value)
      this.totalPriceTarget.value = (unitPrice * quantity).toFixed(2).replace('.', ',')
    } else {
      this.totalPriceTarget.value = ''
    }

    this.invoicesTotalPriceOutlet.computeInvoiceTotal()
  }

  #save() {
    this.form.requestSubmit()
  }
}
