import tippy from 'tippy.js'
import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { content: String, active: Boolean }

  connect() {
    if (this.activeValue) {
      tippy(this.element, {
        content: this.contentValue,
      });
    }
  }
}
