/**
 * Change state of dom element, hide, show, toggle element ...
 */
export default class StateDomElement {
  constructor() { }

  /**
   * Date : 01/10/2024
   * @param {HTMLElement} domEl
   * @param {Array} classNames - CSS classes
   */
  addClass(domEl, classNames) {
    classNames.forEach(item => {
      domEl.classList.add(item)
    })
  }

  /**
   * Date : 01/10/2024
   * @param {HTMLElement} domEl
   * @param {Array} classNames - CSS classes
   */
  removeClass(domEl, classNames) {
    classNames.forEach(item => {
      domEl.classList.remove(item)
    })
  }

  /**
   * Date 12/11/2024
   * Check if a specific attribute has this value
   * @param {HTMLElement} domEl
   * @param {String} key - Attribute name on domEl in html
   * @param {String} valueToVerify - Value to be checked
   * @returns - Returns true if the value of the attribute is equal to that passed in parameter else false
   */
  checkHasAttrWithThisValue(domEl, key, valueToVerify) {
    return domEl.getAttribute(key) === valueToVerify
  }

  /**
   * Date : 01/10/2024
   * @param {HTMLElement} domEl
   */
  disableEl(domEl) {
    domEl.classList.add('is-disabled')
  }

  /**
   * Date : 01/10/2024
   * @param {HTMLElement} domEl
   */
  enableEl(domEl) {
    domEl.classList.remove('is-disabled')
  }

  /**
   * Date 07/10/2024
   * Get value of specific attribute on the domEl
   * @param {HTMLElement} domEl
   * @param {String} key - Attribute name on domEl in html
   */
  getAt(domEl, key) {
    return domEl.getAttribute(key)
  }

  /**
   * Date : 01/10/2024
   * @param {HTMLElement} domEl
   * @param {String} className - Name of the class that I want to verify
   * @returns Boolean.True domEl has class else false
   */
  hasClass(domEl, className) {
    return domEl.classList.contains(className)
  }

  /**
   * Date : 01/10/2024
   * @param {HTMLElement} domEl
   */

  hideEl(domEl) {
    domEl.classList.add('hidden')
  }

  /**
   * Same that hideEl but for tailwind class
   * Date : 01/10/2024
   * @param {HTMLElement} domEl
   */

  hideElTailwind(domEl) {
    domEl.classList.add('tw-hidden')
  }

  /**
   * Add 'tw-hidden' tailwind class for each element of array
   * Date : 01/10/2024
   * @param {Array} domEls
   */

  hideMultipleElTailwind(domEls) {
    domEls.forEach(domEl => {
      domEl.classList.add('tw-hidden')
    })
  }

  /**
   * Date 07/10/2024
   * @param {HTMLElement} domEl
   * Remove html attribute of element
   */

  removeAt(domEl, attribute) {
    domEl.removeAttribute(attribute)
  }

  /**
   * Date 07/10/2024
   * @param {HTMLElement} domEl
   * Update html attribute of element
   */

  setAt(domEl, key, value) {
    domEl.setAttribute(key, value)
  }

  /**
   * Date : 01/10/2024
   * @param {HTMLElement} domEl
   */

  showEl(domEl) {
    if (domEl.classList.contains('hidden')) domEl.classList.remove('hidden')
  }

  /**
   * Same that showEl but for tailwind class
   * Date : 01/10/2024
   * @param {HTMLElement} domEl
   */

  showElTailwind(domEl) {
    if (domEl.classList.contains('tw-hidden')) domEl.classList.remove('tw-hidden')
  }

  /**
   * Remove 'tw-hidden' tailwind class for each element of array
   * Date : 01/10/2024
   * @param {Array} domEls
   */

  showMultipleElTailwind(domEls) {
    domEls.forEach(domEl => {
      if (domEl.classList.contains('tw-hidden')) domEl.classList.remove('tw-hidden')
    })
  }

  /**
   * Date : 01/10/2024
   * @param {HTMLElement} domEl
   * @param {String} className
   */
  toggleClass(domEl, className) {
    domEl.classList.toggle(className)
  }
}
