import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'gmapsAddressInput', 'address', 'city', 'zipcode', 'country']

  connect() {
    this.initGmaps()
  }

  initGmaps() {
    const inputSearch = this.gmapsAddressInputTarget
    this.autocomplete = new window.google.maps.places.Autocomplete(inputSearch)

    this.autocomplete.addListener('place_changed', () => this.onPlaceChanged())
    this.inputTargets.forEach(input => {
      window.google.maps.event.addDomListener(input, 'keydown', e => {
        e.key === 'Enter' && e.preventDefault()
      })
    })
  }

  onPlaceChanged() {
    this.place = this.autocomplete.getPlace()
    if (this.place === undefined) return

    this.addressTarget.value = `${this.#getValue('street_number')} ${this.#getValue('route')}`
    this.cityTarget.value = this.#getValue('locality')
    this.zipcodeTarget.value = this.#getValue('postal_code')
    this.countryTarget.selectize.setValue(this.#getValue('country'))
  }

  #getValue(key) {
    return this.place.address_components.find(e => e.types.includes(key))?.long_name || ''
  }
}
